exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-shared-about-index-js": () => import("./../../../src/pages/components/shared/About/index.js" /* webpackChunkName: "component---src-pages-components-shared-about-index-js" */),
  "component---src-pages-components-shared-edu-index-js": () => import("./../../../src/pages/components/shared/Edu/index.js" /* webpackChunkName: "component---src-pages-components-shared-edu-index-js" */),
  "component---src-pages-components-shared-elements-index-js": () => import("./../../../src/pages/components/shared/Elements/index.js" /* webpackChunkName: "component---src-pages-components-shared-elements-index-js" */),
  "component---src-pages-components-shared-hero-index-js": () => import("./../../../src/pages/components/shared/Hero/index.js" /* webpackChunkName: "component---src-pages-components-shared-hero-index-js" */),
  "component---src-pages-components-shared-icons-dot-js": () => import("./../../../src/pages/components/shared/Icons/Dot.js" /* webpackChunkName: "component---src-pages-components-shared-icons-dot-js" */),
  "component---src-pages-components-shared-icons-triangle-js": () => import("./../../../src/pages/components/shared/Icons/Triangle.js" /* webpackChunkName: "component---src-pages-components-shared-icons-triangle-js" */),
  "component---src-pages-components-shared-layout-index-js": () => import("./../../../src/pages/components/shared/Layout/index.js" /* webpackChunkName: "component---src-pages-components-shared-layout-index-js" */),
  "component---src-pages-components-shared-resume-index-js": () => import("./../../../src/pages/components/shared/Resume/index.js" /* webpackChunkName: "component---src-pages-components-shared-resume-index-js" */),
  "component---src-pages-components-shared-skills-index-js": () => import("./../../../src/pages/components/shared/Skills/index.js" /* webpackChunkName: "component---src-pages-components-shared-skills-index-js" */),
  "component---src-pages-components-shared-social-index-js": () => import("./../../../src/pages/components/shared/Social/index.js" /* webpackChunkName: "component---src-pages-components-shared-social-index-js" */),
  "component---src-pages-components-shared-sphere-index-js": () => import("./../../../src/pages/components/shared/Sphere/index.js" /* webpackChunkName: "component---src-pages-components-shared-sphere-index-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

